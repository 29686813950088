//@ts-check
import * as Sentry from "@sentry/react";

export const initSentry = () => {
    if (process.env.REACT_APP_ENABLE_SENTRY === 'true' && process.env.REACT_APP_SENTRY_DSN) {


        Sentry.init({
            dsn: process.env.REACT_APP_SENTRY_DSN,
            integrations: [
                new Sentry.BrowserTracing(
                  
                ),
                new Sentry.Replay()
            ],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            tracesSampleRate: 1.0,

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

    }
};
